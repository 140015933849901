






















































































































import { Component, Vue } from "vue-property-decorator";
import invetarioModule from "@/store/modules/invetario-module";
import { invetario } from "@/shared/dtos/invetario";
import { RouterNames } from "@/router/routernames";
import { API } from "@/shared/api";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
@Component({
  components: {
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    DxDateBox: () => import("devextreme-vue/date-box"),
    DocumentsHandler: () =>
      import("@/views/Documentos/DocumentsHandler.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ficha_inventario extends Vue {
  created() {
    if (this.is_new()) {
      invetarioModule.onGetinvetario(new invetario());
      this.datasource.id_categroria = Number.parseInt(
        this.$route.query.id_categoria.toString()
      );
    } else {
      invetarioModule.getinvetario(this.$route.params.id).then(() => {
        this.get_files();
      });
    }
  }

  public get datasource() {
    return invetarioModule.invetario;
  }

  public get files() {
    return invetarioModule.files;
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
  public cancelar() {
    this.$router.push({ name: RouterNames.inventario_treeview });
  }

  public eliminar() {
    invetarioModule.eliminarinvetario(this.datasource).then(()=> this.cancelar());
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          invetarioModule
            .guardarinvetario(this.datasource)
            .then(() => this.cancelar());
        } else {
          invetarioModule
            .modificarinvetario(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }
  public get_files() {
    if (this.datasource.id === null) {
      return;
    }
    return invetarioModule.getfiles(this.datasource.id).catch(() => []);
  }

  public Geturl() {
    return API.invetario + "/documents/" + this.datasource.id;
  }

  public delete_file(file: document_fileDto): void {
    invetarioModule
      .eliminar_documento({
        object: this.datasource,
        doc: file,
      })
      .then(() => {
        this.get_files();
      });
  }

  public downlod_file(file: document_fileDto) {
    window.open(
      API.webApiBaseinventario + this.datasource.id + "/" + file.name
    );
  }
}
