import { BaseDto } from '@/shared/dtos/base-dto';

export class document_fileDto extends BaseDto {
    public creationTime!:Date;
    public lastWriteTime!:Date;
    public size !: number;
    public hasChilds !: boolean;
    public isFile !: boolean;
    public isDirectory !: boolean;
    public name !: string;
    public version !: string;
    public imageRuntimeVersion !: string;
    public compilation !: string;
    public extension !: string;
 } 
